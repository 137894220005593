import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { PrimeNGConfig } from 'primeng/api'

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  constructor(
    private translateService: TranslateService,
    private primengConfig: PrimeNGConfig
  ) {}

  load() {
    // Idioma default
    this.translateService.setDefaultLang('en')

    const userLanguage =
      localStorage.getItem('user.language') ?? navigator.language

    // Idioma actual
    if (userLanguage.startsWith('es')) {
      this.translate('es')
    } else {
      this.translate('en')
    }
  }

  getLanguage(): LanguageCode {
    switch (this.translateService.currentLang) {
      case 'en':
        return LanguageCode.EN
      case 'es':
        return LanguageCode.ES
      default:
        return LanguageCode.EN
    }
  }

  setLanguage(code: LanguageCode) {
    switch (code) {
      case LanguageCode.EN:
        localStorage.setItem('user.language', 'en')
        this.translate('en')
        break
      case LanguageCode.ES:
        localStorage.setItem('user.language', 'es')
        this.translate('es')
        break
    }
  }

  getService() {
    return this.translateService
  }

  private translate(code: string) {
    console.log('USING LANG: ' + code)
    this.translateService.use(code)
    this.translateService
      .get('primeng')
      .subscribe((res) => this.primengConfig.setTranslation(res))
  }
}

export enum LanguageCode {
  EN,
  ES
}
