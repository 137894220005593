import { CommonModule, DOCUMENT } from '@angular/common'
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { DividerModule } from 'primeng/divider'
import { PanelModule } from 'primeng/panel'
import { SelectButtonModule } from 'primeng/selectbutton'
import {
  AIModalDisplayMode,
  AIModalSelectedPrice,
  ZergroshService
} from 'src/app/core/services/zergrosh.service'
import {
  ShopProductType,
  StripeProductPriceModel
} from 'src/app/core/models/zergrosh-model'
import { LoaderService } from 'src/app/core/services/loader.service'
import { SkeletonModule } from 'primeng/skeleton'
import { Dialog, DialogModule } from 'primeng/dialog'
import { DiscordGuildModel } from 'src/app/core/models/discord-model'
import { AvatarModule } from 'primeng/avatar'
import { DiscordService } from 'src/app/core/services/discord.service'
import { Subscription, forkJoin } from 'rxjs'
import { MessageService } from 'primeng/api'
import { LoginService } from 'src/app/core/services/login.service'
import { ServerDropdownInputComponent } from 'src/app/commons/components/panel/form/inputs/server-dropdown-input/server-dropdown-input.component'
import { PopupFullscreenComponent } from 'src/app/core/components/layout/popup-fullscreen/popup-fullscreen.component'

@Component({
  selector: 'app-ai-plans-page',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    DividerModule,
    PanelModule,
    SelectButtonModule,
    SkeletonModule,
    DialogModule,
    AvatarModule,
    ServerDropdownInputComponent,
    PopupFullscreenComponent,
    PanelModule,
    DividerModule
  ],
  templateUrl: './ai-plans-page.component.html',
  styleUrl: './ai-plans-page.component.scss'
})
export class AiPlansPageComponent implements OnInit, OnDestroy {
  faCheck = faCheck
  faQuestion = faQuestionCircle

  @Input()
  displayMode: AIModalDisplayMode = 'all'

  @Input()
  displayServerId = ''

  @Input()
  selectedPriceType: AIModalSelectedPrice = 'personal'

  displayServerModel?: DiscordGuildModel

  priceTypeOptions: { label: string; value: string }[] = []

  loading = true
  prices: StripeProductPriceModel[] = []

  showServerSelectorDialog = false
  serverProductToBuy?: ShopProductType

  selectedServer?: DiscordGuildModel
  servers: DiscordGuildModel[] = []

  loginStateSubscription?: Subscription
  destroyed = false

  faqRedirected = false

  @ViewChild('primeDialog')
  displayPrime!: Dialog

  @Output()
  escButtonClick = new EventEmitter<void>()

  constructor(
    private zergroshService: ZergroshService,
    private loaderService: LoaderService,
    @Inject(DOCUMENT) private document: Document,
    private discordService: DiscordService,
    private messageService: MessageService,
    private loginService: LoginService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.displayMode === 'personal') {
      this.selectedPriceType = 'personal'
    } else if (this.displayMode === 'server') {
      this.selectedPriceType = 'server'
    }

    this.computeOptions()
    this.retrieveProducts()

    this.translateService.onLangChange.subscribe({
      next: () => {
        this.computeOptions()
      }
    })
  }

  computeOptions() {
    forkJoin([
      this.translateService.get('zergrosh-ai-plans.selector.personal'),
      this.translateService.get('zergrosh-ai-plans.selector.server')
    ]).subscribe({
      next: (translations) => {
        this.priceTypeOptions = []
        this.priceTypeOptions.push({
          label: translations[0],
          value: 'personal'
        })
        this.priceTypeOptions.push({
          label: translations[1],
          value: 'server'
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed = true
    this.loginStateSubscription?.unsubscribe()
  }

  getProductPrice(type?: ShopProductType) {
    const price = this.prices.find((e) => e.productType === type)
    if (price === undefined) {
      return 'ERROR'
    }

    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(price.productPrice)
  }

  getProductCurrency(type?: ShopProductType) {
    const price = this.prices.find((e) => e.productType === type)
    if (price === undefined) {
      return 'ERROR'
    }

    return price.currency
  }

  getProductCredits(type?: ShopProductType) {
    if (type === undefined) return 'ERROR'

    const price = this.prices.find((e) => e.productType === type)
    if (price === undefined) {
      return 'ERROR'
    }

    return price.productCredits
  }

  retrieveProducts() {
    if (this.destroyed) return

    this.zergroshService.getProductPrices().subscribe({
      next: (productsRes) => {
        if (this.destroyed) return

        if (
          this.loginService.loginState.getValue() === 'LOGGED' &&
          this.displayServerId !== ''
        ) {
          this.discordService.fetchServers().subscribe({
            next: (cachedServers) => {
              if (this.displayServerId !== '') {
                const server = cachedServers.success
                  ? cachedServers.data.find(
                      (s) => s.id === this.displayServerId
                    )
                  : undefined
                if (server !== undefined) {
                  this.displayServerModel = server
                }
              }

              if (productsRes.success) {
                setTimeout(() => {
                  this.loading = false
                  this.prices = productsRes.data
                }, 1000)
              }
            },
            error: () => {
              if (productsRes.success) {
                setTimeout(() => {
                  this.loading = false
                  this.prices = productsRes.data
                }, 1000)
              }
            }
          })
        } else {
          if (productsRes.success) {
            setTimeout(() => {
              this.loading = false
              this.prices = productsRes.data
            }, 1000)
          }
        }
      },
      error: () => {
        if (this.destroyed) return

        forkJoin([
          this.translateService.get(
            'zergrosh-ai-plans.errors.error-fetching-products.title'
          ),
          this.translateService.get(
            'zergrosh-ai-plans.errors.error-fetching-products.description'
          )
        ]).subscribe({
          next: (translations) => {
            this.messageService.add({
              severity: 'error',
              summary: translations[0],
              detail: translations[1]
            })
          }
        })

        setTimeout(() => {
          this.retrieveProducts()
        }, 4000)
      }
    })
  }

  createPaymentForServer(product: ShopProductType) {
    if (this.loginService.loginState.value !== 'LOGGED') {
      // Hay que loguearlo...
      this.loginService.openLoginPopUp().subscribe()
      this.loginStateSubscription = this.loginService.loginState.subscribe({
        next: (newLoginState) => {
          if (newLoginState === 'LOGGED') {
            this.loginStateSubscription?.unsubscribe()
            this.createPaymentForServer(product)
          } else if (newLoginState === 'NONE') {
            this.loginStateSubscription?.unsubscribe()
          }
        }
      })
      return
    }

    // El modal tiene un servidor a mostrar
    if (this.displayServerModel !== undefined) {
      this.selectedServer = this.displayServerModel
      this.serverProductToBuy = product
      this.submitPaymentForServer()
      return
    }

    this.selectedServer = undefined
    this.serverProductToBuy = product

    this.loaderService.setDisplay(true)
    this.fetchServers().subscribe({
      next: (cached) => {
        this.servers = this.zergroshService.filterManageableGuilds(
          cached.success ? cached.data : []
        )
        this.showServerSelectorDialog = false
        setTimeout(() => {
          this.loaderService.setDisplay(false)
          this.showServerSelectorDialog = true
        }, 250)
      },
      error: () => {
        this.loaderService.setDisplay(false)

        forkJoin([
          this.translateService.get(
            'zergrosh-ai-plans.errors.error-creating-payment-for-server.title'
          ),
          this.translateService.get(
            'zergrosh-ai-plans.errors.error-creating-payment-for-server.description'
          )
        ]).subscribe({
          next: (translations) => {
            this.messageService.add({
              severity: 'error',
              summary: translations[0],
              detail: translations[1]
            })
          }
        })
      }
    })
  }

  submitPaymentForServer() {
    if (this.selectedServer === undefined) return
    if (this.serverProductToBuy === undefined) return
    this.createPayment(this.serverProductToBuy, this.selectedServer.id)
  }

  createPayment(product: ShopProductType, forServerId?: string) {
    if (this.loginService.loginState.value !== 'LOGGED') {
      // Hay que loguearlo...
      this.loginService.openLoginPopUp().subscribe()
      this.loginStateSubscription = this.loginService.loginState.subscribe({
        next: (newLoginState) => {
          if (newLoginState === 'LOGGED') {
            this.loginStateSubscription?.unsubscribe()
            this.createPayment(product, forServerId)
          } else if (newLoginState === 'NONE') {
            this.loginStateSubscription?.unsubscribe()
          }
        }
      })
      return
    }

    this.loaderService.setDisplay(true)
    this.zergroshService
      .createPayment({
        products: [
          {
            type: product,
            amount: 1
          }
        ],
        returnURL: window.location.href.split('?')[0].split('#')[0],
        forServerId
      })
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.document.location.href = res.data
          } else {
            this.loaderService.setDisplay(false)
          }
        },
        error: () => {
          this.loaderService.setDisplay(false)

          forkJoin([
            this.translateService.get(
              'zergrosh-ai-plans.errors.error-creating-payment.title'
            ),
            this.translateService.get(
              'zergrosh-ai-plans.errors.error-creating-payment.description'
            )
          ]).subscribe({
            next: (translations) => {
              this.messageService.add({
                severity: 'error',
                summary: translations[0],
                detail: translations[1]
              })
            }
          })
        }
      })
  }

  fetchServers() {
    return this.discordService.fetchServers(true)
  }

  getLoginState() {
    return this.loginService.loginState.value
  }
}
