<div class="fullscreen bg-body">
  <div class="header-content ps-3 pe-3" *ngIf="windowWidth < 992">
    <div class="mb-2 d-flex justify-content-end">
      <div class="close-button d-inline-block mt-4" (click)="handleEscButtonClick()">
        <div class="text-center">
          <button class="btn btn-secondary"><fa-icon [icon]="faXmark" size="xl" class="me-2" /> {{ 'zergrosh-ai-plans.buttons.close' | translate }}</button>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </div>
  </div>
  <div class="d-flex flex-row header-content" *ngIf="windowWidth >= 992">
    <div class="flex-grow-1 mt-5 ms-5">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </div>
    <div class="ms-3">
      <div class="close-button d-inline-block mt-5 me-5" (click)="handleEscButtonClick()">
        <div class="text-center">
          <fa-icon [icon]="faCircleXmark" size="3x" />
        </div>
        <div class="text-center mt-1">
          ESC
        </div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
<ng-template #contentTpl><ng-content select="[header]"></ng-content></ng-template>
