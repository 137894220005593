import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-popup-fullscreen',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, TranslateModule],
  templateUrl: './popup-fullscreen.component.html',
  styleUrl: './popup-fullscreen.component.scss'
})
export class PopupFullscreenComponent implements OnInit, OnDestroy {
  faCircleXmark = faCircleXmark
  faXmark = faXmark

  windowWidth: number = 0

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth
  }

  @Output()
  escButtonClick = new EventEmitter<void>()

  ngOnInit(): void {
    this.windowWidth = window.innerWidth
    document.body.style.setProperty('overflow-y', 'hidden', 'important')
  }

  ngOnDestroy(): void {
    document.body.style.setProperty('overflow-y', 'scroll', 'important')
  }

  handleEscButtonClick() {
    this.escButtonClick.emit()
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.escButtonClick.emit()
    }
  }
}
